import ApiService from "@/core/services/ApiService";
import Config from "@/core/config/ApiConfig";

export default {
    // Address information
    getAddressList(params) {
        return ApiService.post(
            Config.baseApiUrl + "/api/basic-data-management/city/list",
            params
        );
    },
    addAddress(params) {
        return ApiService.post(
            Config.baseApiUrl + "/api/basic-data-management/city/store",
            params
        );
    },
    updateAddress(params) {
        return ApiService.post(
            Config.baseApiUrl + "/api/basic-data-management/city/update",
            params
        );
    },
    getAddressEditable(params){
        return ApiService.post(
            Config.baseApiUrl + "/api/basic-data-management/city/editable",
            params
        );
    },
    deleteAddress(params) {
        return ApiService.post(
            Config.baseApiUrl + "/api/basic-data-management/city/delete",
            params
        );
    },
    exportAddressList(params) {
        return ApiService.post(
            Config.baseApiUrl + "/api/basic-data-management/city/export",
            params,
            { responseType: "blob" }
        );
    },
};
