import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { MBLayout, MBError404, MBError500 } from "magicbean-saas-common";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: MBLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/user-management/system-users",
        name: "user-management-system-users",
        component: () =>
          import("@/views/user-management/system-users/Index.vue"),
      },
      {
        path: "/user-management/system-users/:id",
        name: "user-management-system-users-detail",
        component: () =>
          import("@/views/user-management/system-users/Detail.vue"),
        children: [
          {
            path: "overview",
            name: "user-management-system-users-overview",
            component: () =>
              import("@/views/user-management/system-users/Overview.vue"),
          },
          {
            path: "settings",
            name: "user-management-system-users-settings",
            component: () =>
              import("@/views/user-management/system-users/Settings.vue"),
          },
          {
            path: "permission",
            name: "user-management-system-users-permission",
            component: () =>
              import("@/views/user-management/system-users/Permission.vue"),
          },
        ],
      },
      {
        path: "/user-management/saas-users/",
        name: "user-management-saas-users",
        component: () => import("@/views/user-management/saas-users/Index.vue"),
      },
      {
        path: "/user-management/saas-users//:id",
        name: "user-management-saas-users-detail",
        component: () =>
          import("@/views/user-management/system-users/Detail.vue"),
        children: [
          {
            path: "overview",
            name: "user-management-saas-users-overview",
            component: () =>
              import("@/views/user-management/system-users/Overview.vue"),
          },
          {
            path: "settings",
            name: "user-management-saas-users-settings",
            component: () =>
              import("@/views/user-management/system-users/Settings.vue"),
          },
          {
            path: "permission",
            name: "user-management-saas-users-permission",
            component: () =>
              import("@/views/user-management/system-users/Permission.vue"),
          },
          {
            path: "managed-customers",
            name: "user-management-sass-users-managed-customers",
            component: () =>
              import(
                "@/views/user-management/system-users/ManagedCustomers.vue"
              ),
          },
          {
            path: "managed-merchants",
            name: "user-management-sass-users-managed-merchants",
            component: () =>
              import(
                "@/views/user-management/system-users/ManagedMerchants.vue"
              ),
          },
        ],
      },
      {
        path: "/profile",
        name: "user-profile",
        component: () =>
          import("@/views/user-management/system-users/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import(
                "@/views/user-management/system-users/ProfileOverview.vue"
              ),
          },
          {
            path: "settings",
            name: "profile-settings",
            component: () =>
              import(
                "@/views/user-management/system-users/ProfileSettings.vue"
              ),
          },
        ],
      },
      {
        path: "/permissions/app",
        name: "app",
        component: () => import("@/views/app/Index.vue"),
      },
      {
        path: "/permissions/app/:id",
        name: "app-detail",
        component: () => import("@/views/app/Detail.vue"),
      },
      {
        path: "/permissions/menu",
        name: "menu",
        component: () => import("@/views/menu/Index.vue"),
      },
      {
        path: "/permissions/menu/:id",
        name: "menu-detail",
        component: () => import("@/views/menu/Detail.vue"),
      },
      {
        path: "/permissions/role",
        name: "role",
        component: () => import("@/views/role/Index.vue"),
      },
      {
        path: "/permissions/role/:id",
        name: "role-detail",
        component: () => import("@/views/role/Detail.vue"),
      },
      {
        path: "/master-data/city-management",
        name: "master-data-city-management",
        component: () =>
          import("@/views/master-data/city-management/Index.vue"),
      },
      {
        path: "/style-guide",
        name: "style-guide",
        component: () => import("@/views/style-guide/Index.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/authentication/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/authentication/SignIn.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: MBError404,
  },
  {
    path: "/500",
    name: "500",
    component: MBError500,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (to.meta.contentWidth == "fluid") {
    store.commit(Mutations.OVERRIDE_PAGE_LAYOUT_CONFIG, {
      content: {
        width: "fluid",
      },
    });
  }

  if (to.name !== "sign-in") {
    store.dispatch(Actions.VERIFY_AUTH, () => {
      store.dispatch(Actions.GET_MENU_ITEMS, {
        path: to.path,
        callback: () => {
          next();
        },
      });
    });
    next();
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
